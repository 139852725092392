html,
body,
#root {
  height: 100%;
  font-size: 14px;
}

* {
  border-radius: 0;
}

body {
  //TODO: THink about good cyrillic font
  font-family: 'Roboto', Arial, sans-serif !important;
}

.react-datepicker {
  &__close-icon {
    &:after {
      background-color: transparent;
      color: hsl(0, 0%, 80%);
      height: 22px;
      padding: 0;
      width: 22px;
      font-size: 22px;
    }
  }

  &__input-container {
    input {
      &:focus {
        + .react-datepicker__close-icon {
          &:after {
            color: hsl(0, 0%, 40%);
          }
        }
      }
    }
  }

  &-popper {
    width: 400px;
  }

  &-wrapper {
    width: 100%;

    >div {
      width: 100%;
    }
  }
}

// https://github.com/Hacker0x01/react-datepicker/issues/624#issuecomment-261696513
.react-datepicker {
  font-size: 1em;
}

.react-datepicker__header {
  padding-top: 0.8em;
}

.react-datepicker__month {
  margin: 0.4em 1em;
}

.react-datepicker__day-name,
.react-datepicker__day {
  width: 1.9em;
  line-height: 1.9em;
  margin: 0.166em;
}

.react-datepicker__current-month {
  font-size: 1em;
  display: none;
}

.react-datepicker__navigation {
  top: 1em;
  line-height: 1.7em;
  border: 0.45em solid transparent;
}

.react-datepicker__navigation--previous {
  border-right-color: #ccc;
  left: 1em;
}

.react-datepicker__navigation--next {
  border-left-color: #ccc;
  right: 1em;
}

.fa-fw-custom {
  width: 1em;
  text-align: center;
}

.nav-tabs {
  display: flex;
  width: 100%;

  li {
    margin-bottom: 0;
  }

  li>a {
    border: none;
    border-radius: 0;
    margin-right: 0;
    color: $color-gray-text;
    height: $panel-height;
    padding: 0 15px;
    line-height: $panel-height;

    &:hover {
      color: $color-gray;
      border: none;
      border-bottom: 2px solid $color-green;
      background-color: $layout-header-color;
    }
  }

  li.active,
  li:active,
  li:hover,
  li:focus {

    >a,
    a:hover,
    a:focus,
    a:active {
      border: none;
      border-bottom: 2px solid $color-green;
      background-color: $layout-header-color;
    }
  }
}

.nav-stacked {
  li>a {
    font-size: 16px;
    height: $panel-height;
    line-height: $panel-height;
    padding: 0 15px;

    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: $color-black;
      color: $color-white;

      i {
        color: $color-green;
      }
    }
  }

  li+li {
    margin-top: 0;
  }
}

input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button;
}

.panel.panel-default {
  border: none;
}

.dropdown-menu>li>a {
  padding: 0;

  &:hover {
    background: transparent;
  }
}

.dropdown-menu {
  z-index: 1001;
}

.btn-link {
  outline: none;
  padding: 6px 0;
  box-shadow: none;
  text-decoration: underline;
  &:hover {
    color: #069c61;
    text-decoration: underline;
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.ant-table {
  .ant-table-placeholder {
    z-index: 0;
  }
  &-thead {
    > tr {
      > th {
        padding: 12px 16px;
      }
    }
  }
}

.nowrap {
  white-space: nowrap;
  padding-right: 3px;
  overflow: hidden;

  &.ellipsis {
    text-overflow: ellipsis;
  }
}

.align-left {
  text-align: left !important;
}

.align-right {
  text-align: right;
}

.am.btn-default {
  color: #069c61;
  background-color: #fff;
  border-color: #069c61;

  &:hover {
    background-color: rgba(6, 156, 97, 0.2);
  }

  &.active {
    color: #fff;
    background-color: rgba(6, 156, 97, 0.8);
  }
}

.tooltips {
  font-size: 12px;
  color: #9b9b9b;
}

.tooltip-popup {
  p {
    margin: 0;
    padding: 0 0 6px 0;

    &:last-of-type {
      padding: 0;
    }
  }
}

label {
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 400;
  display: inline-flex;
  align-items: center;
}

.ant-message {
  z-index: 9999 !important;

  &-custom-content {
    display: flex;

    & .anticon-close-circle {
      line-height: 16px;
    }
  }
}
